import { ADMIN_IDS } from '~/server/utils/constants'

export default defineNuxtRouteMiddleware(async () => {
  const { loggedIn, user } = useUserSession()

  if (!loggedIn.value) {
    return navigateTo('/login')
  }
  
  if (!ADMIN_IDS.includes(user.value!.id)) {
    return navigateTo('/')
  }
}) 